import React from 'react';
import * as skeletonHtmls from '../src/skeleton';

const RootComponent = React.lazy(() => import('./root.component.js'));

const RootLoader = (props) => {
  const microfrontendRouter = window.Shell?.v1?.microfrontendRouter;
  const currentRoute = microfrontendRouter?.getState()?.content;

  const skeletonFileName = () => {
    const skeletonReference = currentRoute?.skeletonReference || 'index';
    return skeletonReference.endsWith('.html')
      ? skeletonReference.slice(0, -5)
      : skeletonReference;
  };

  const skeletonHtml = skeletonHtmls?.[skeletonFileName()];

  return (
    <section
      style={{
        position: 'relative',
        maxWidth: '100%',
        maxHeight: '100%',
        zIndex: 0
      }}
    >
      <React.Suspense
        fallback={
          <div
            style={{
              position: 'absolute',
              maxWidth: '100%',
              maxHeight: '100%',
              zIndex: -1
            }}
            dangerouslySetInnerHTML={{ __html: skeletonHtml }}
          />
        }
      >
        <RootComponent {...props} />
      </React.Suspense>
    </section>
  );
};

export default RootLoader;
